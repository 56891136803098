import React, {
  useContext,
} from 'react';
import propTypes from 'prop-types';

import Context from '../../ContextProvider';

import FormSection from './FormSection';

function CustomerForm({ results, updateSection }) {
  const { form } = useContext(Context);
  const diagnosticForm = form.filter(({ key }) => (
    key !== 'personalDetails' && key !== 'solutions'
  ));

  if (!diagnosticForm) return null;
  return (
    <>
      {diagnosticForm.map((section) => (
        <FormSection
          key={section.key}
          results={results[section.key]}
          section={section}
          updateSection={updateSection}
        />
      ))}
    </>
  );
}
CustomerForm.propTypes = {
  updateSection: propTypes.func.isRequired,
  results: propTypes.instanceOf(Object).isRequired,
};

export default CustomerForm;
