import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Context from '../../ContextProvider';

function Header() {
  const { logo, userKey } = useContext(Context);
  return (
    <header>
      <Link to={`/?key=${userKey}`}>
        {logo}
      </Link>
    </header>
  );
}

export default Header;
