import React, { useContext, useState } from 'react';
import propTypes from 'prop-types';

import Context from '../../ContextProvider';

import './Empfehlungen.css';
import {
  Select,
  MultipleSelect,
} from './Inputs';

const form = [
  {
    key: 'Kategorie',
    label: 'Kategorie',
  },
  {
    key: 'Hersteller',
    label: 'Hersteller',
  },
  {
    key: 'Bauform',
    label: 'Bauform',
  },
  {
    key: 'Aurality',
    label: 'Binaural oder Monaural',
  },
  {
    key: 'Artikel',
    label: 'Artikel',
  },
  {
    key: 'Otoplastiken',
    label: 'Otoplastiken',
  },
  {
    key: 'Zubehoer',
    label: 'Artikeloptionen',
    multiple: true,
  },
];

const ensureNullOption = (choices) => {
  if (!choices.find(({ id }) => id === 0)) {
    choices.unshift({ id: 0, name: 'Keine' });
  }
  return choices;
};
const ensureAllNullOptions = (options) => {
  const ensured = {};
  Object.keys(options).forEach((key) => {
    ensured[key] = ensureNullOption(
      JSON.parse(JSON.stringify(options[key])),
    );
  });
  return ensured;
};

function Instructions() {
  const text = 'Wählen Sie mit dem Formular unten Artikel aus.';
  return (
    <table>
      <thead>
        <tr>
          <td>{text}</td>
        </tr>
      </thead>
    </table>
  );
}

function PrivateOrPublicLabel({ privat }) {
  const privateMessage = 'Dieser Kunde ist privat versichert.';
  const publicMessage = 'Dieser Kunde ist gesetzlich versichert.';
  const priceDisclaimer = 'Alle Preise werden dementsprechend berechnet.';

  return (
    <h4>
      {privat
        ? privateMessage
        : publicMessage}
      {' '}
      {priceDisclaimer}
    </h4>
  );
}
PrivateOrPublicLabel.propTypes = {
  privat: propTypes.bool.isRequired,
};

function EmpfehlungenTable({ chosenArtikel, deleteArtikel }) {
  return (
    <table>
      <thead>
        <tr>
          {form.map(({ label }) => (
            <th key={label}>{label}</th>
          ))}
          <th>Gesamtpreis</th>
          <th>{' '}</th>
        </tr>
      </thead>
      <tbody>
        {chosenArtikel.map((artikel, index) => (
          <tr key={JSON.stringify(artikel)}>
            {form.map(({ key }) => (
              <td key={key}>{artikel[key]}</td>
            ))}
            <td>
              {artikel.Gesamtpreis
                .split('\n')
                .map((item) => <p>{item}</p>)}
            </td>
            <td>
              <button
                type="button"
                onClick={() => { deleteArtikel(index); }}
              >
                Löschen
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
EmpfehlungenTable.propTypes = {
  chosenArtikel: propTypes.arrayOf(
    propTypes.instanceOf(Object),
  ).isRequired,
  deleteArtikel: propTypes.func.isRequired,
};

const pricedKeys = ['Artikel', 'Otoplastiken', 'Zubehoer'];
function Empfehlungen({
  savedArtikel,
  updateSavedArtikel,
}) {
  const {
    artikelOptions: options,
    responses,
    rabatt,
    optionenInfo,
  } = useContext(Context);
  const [questionChoices, setQuestionChoices] = useState(
    ensureAllNullOptions(options),
  );
  const [answers, setAnswers] = useState({});
  const [chosenArtikel, setChosenArtikel] = useState(
    savedArtikel,
  );
  const [popup, setPopup] = useState(null);
  const privat = responses
    .personalDetails
    .Versicherungsart === 'privat';

  const getPrice = (question, answer, aurality) => {
    const prices = {
      basePrice: 0,
      insuredPrice: 0,
      monthly: 0,
    };

    const choice = options[question]
      .find(({ id }) => String(id) === String(answer));
    if (!choice) return prices;

    const type = privat ? 'privat' : 'zz';
    const months = privat ? 30 : 36;
    const monthKey = `treueclub_${months}m`;
    const totalRabatt = aurality === 'binaural'
      ? rabatt.binaural
      : rabatt.monaural;
    const calculateInsuredPrice = (basePrice) => {
      if (privat) return basePrice;
      if (question === 'Zubehoer') return basePrice;
      const difference = basePrice - totalRabatt;
      if (difference < 0) return 0;
      return difference;
    };

    prices.monthly = choice[monthKey] || 0;
    prices.basePrice = choice[`preis_${type}_${aurality}`] || 0;
    prices.insuredPrice = calculateInsuredPrice(prices.basePrice);
    return prices;
  };

  const ensureAuralityChoices = () => {
    options.Aurality = [
      { id: 'monaural', name: 'Monaural' },
      { id: 'binaural', name: 'Binaural' },
    ];
  };
  /* eslint no-param-reassign: off */
  const ensureNames = (choices) => {
    choices.forEach((choice) => {
      const { name, label } = choice;
      if (name) return;
      if (label) { choice.name = label; return; }
      choice.name = '';
    });
    return choices;
  };
  const displayPrices = (choices, key) => {
    if (!pricedKeys.includes(key)) return choices;
    choices.forEach((choice) => {
      if (choice.preis) {
        choice.name = `${choice.label}, ${choice.preis}€`;
        return;
      }
      if (!answers.Aurality) return;
      const aurality = answers.Aurality;
      const { basePrice } = getPrice(key, choice.id, aurality);
      if (!basePrice) return;
      choice.name = `${choice.label}, ${basePrice}€`;
    });
    return choices;
  };

  const filterOtoplastiken = () => {
    const choices = ensureNullOption(JSON.parse(JSON.stringify(options.Otoplastiken)));
    return displayPrices(choices, 'otoplastiken');
  };

  const filterArtikel = (filters = answers) => {
    const choices = ensureNullOption(JSON.parse(JSON.stringify(options.Artikel)));
    return choices.filter(({
      produktgruppe,
      hersteller_id: hersteller,
      name,
      bauform,
    }) => {
      if (name === 'Keine') return true;
      if (filters.Kategorie && produktgruppe !== filters.Kategorie) return false;
      if (filters.Hersteller && hersteller !== `${filters.Hersteller}`) return false;
      if (filters.Bauform && bauform !== filters.Bauform) return false;
      return true;
    });
  };

  const filterArtikelOptionen = (choices) => choices
    .filter(({ artikel_or_option_id: id, name }) => {
      if (name === 'Keine') return true;
      const option = optionenInfo
        .find(({ id: optionID }) => `${optionID}` === `${id}`);
      if (!option) return false;
      if (!option.artikel_ids) return false;
      const artikelIDs = option
        .artikel_ids.map((number) => `${number}`);
      if (!artikelIDs.includes(answers.Artikel)) return false;
      return true;
    });

  const filterChoices = (choices, question) => {
    const validChoices = [];
    choices.forEach((choice) => {
      const filter = { ...answers, [question]: choice.id };
      const validArtikel = filterArtikel(filter);
      if (validArtikel.length > 1) validChoices.push(choice);
    });
    return validChoices;
  };

  ensureAuralityChoices();
  const updateChoices = (question) => {
    let choices = JSON.parse(JSON.stringify(
      options[question],
    ));
    choices = ensureNullOption(choices);
    choices = ensureNames(choices);

    switch (question) {
      case 'Zubehoer':
        choices = filterArtikelOptionen(choices);
        break;
      case 'Otoplastiken':
        choices = filterOtoplastiken(choices);
        break;
      case 'Artikel':
        choices = filterArtikel();
        break;
      default: choices = filterChoices(choices, question);
    }

    choices = displayPrices(choices, question);
    return choices;
  };

  const isReadOnly = (index) => {
    const questionKey = form[index].key;

    if (questionKey === 'Zubehoer'
      && answers.Artikel) return false;

    for (let i = 0; i < index; i += 1) {
      if (!answers[form[i].key]) return true;
    }
    return false;
  };

  const answerQuestion = (rawAnswer, key, multiple) => {
    const answer = String(rawAnswer);
    const assignAnswer = () => {
      if (answer === '0') { answers[key] = undefined; return; }
      if (!multiple) { answers[key] = answer; return; }
      const isArray = Array.isArray(answers[key]);
      if (!isArray) { answers[key] = [answer]; return; }
      const index = answers[key]
        .findIndex((val) => val === answer);
      if (index === -1) { answers[key].push(answer); return; }
      const before = answers[key].slice(0, index);
      const after = answers[key].slice(index + 1);
      answers[key] = before.concat(after);
    };
    assignAnswer();

    const index = form.findIndex((field) => key === field.key);
    form.forEach((field, fieldIndex) => {
      if (fieldIndex <= index) return;
      if (answer === '0') { answers[field.key] = undefined; return; }
      if (fieldIndex > index + 1) {
        questionChoices[field.key] = ensureNullOption([]);
        return;
      }

      const newChoices = updateChoices(field.key);
      questionChoices[field.key] = newChoices;
      if (Array.isArray(answers[field.key])) {
        answers[field.key] = answers[field.key]
          .filter((val) => newChoices
            .find(({ id }) => id === val));
      } else {
        answers[field.key] = newChoices
          .find(({ id }) => id === answers[field.key]);
      }
    });
    setQuestionChoices({ ...questionChoices });
    setAnswers({ ...answers });
  };

  const onSubmit = () => {
    const artikelInfo = {};
    const questions = Object.keys(answers);
    let prices = [];
    const priceToString = (price) => price
      .toLocaleString(
        'de-DE',
        { style: 'currency', currency: 'EUR' },
      );
    questions.forEach((question) => {
      const choices = questionChoices[question];
      const selection = Array.isArray(answers[question])
        ? answers[question]
        : [answers[question]];
      const labels = [];
      selection.forEach((value) => {
        const itemPrices = getPrice(question, value, answers.Aurality);
        prices.push(itemPrices);
        const choice = choices
          .find(({ id }) => `${id}` === `${value}`);
        if (!choice) return;

        const label = choice.label || choice.name;
        if (question !== 'Zubehoer') {
          labels.push(label);
          return;
        }

        const { basePrice } = itemPrices;
        const parts = [label];
        if (basePrice) parts.push(priceToString(basePrice));
        labels.push(parts.join(': '));
      });
      artikelInfo[question] = labels.join(': ');
    });
    prices = prices.reduce(
      (sum, currentValue) => {
        const keys = Object.keys(sum);
        const newSum = {};
        keys.forEach((key) => {
          newSum[key] = sum[key] + currentValue[key];
        });
        return newSum;
      },
      { basePrice: 0, insuredPrice: 0, monthly: 0 },
    );
    artikelInfo.Gesamtpreis = [
      privat
        ? `Privatpreis: ${priceToString(prices.basePrice)}`
        : `Eigenpreis: ${priceToString(prices.insuredPrice)}`,
      `Treueclub: ${priceToString(prices.monthly)}`,
    ].join('\n');
    chosenArtikel.push(artikelInfo);
    setAnswers({});
    setChosenArtikel([...chosenArtikel]);
    updateSavedArtikel([...chosenArtikel]);
  };

  const deleteArtikel = (index) => {
    const before = chosenArtikel.slice(0, index);
    const after = chosenArtikel.slice(index + 1);
    const newChosenArtikel = before.concat(after);
    updateSavedArtikel([...newChosenArtikel]);
    setChosenArtikel([...newChosenArtikel]);
  };

  return (
    <>
      <section>
        <h2>Artikelempfehlungen</h2>
        <PrivateOrPublicLabel privat={privat} />
        {chosenArtikel.length
          ? (
            <EmpfehlungenTable
              form={form}
              chosenArtikel={chosenArtikel}
              deleteArtikel={deleteArtikel}
            />
          ) : <Instructions />}
      </section>
      <section className="empfehlungen">
        {form.map(({ key, label }, index) => (
          <form key={key}>
            {!form[index].multiple && (
              <Select
                value={answers[key] || 0}
                question={{
                  label,
                  values: questionChoices[key] || [],
                  readOnly: isReadOnly(index),
                }}
                setValue={(answer) => {
                  answerQuestion(answer, key);
                }}
                index={index}
              />
            )}
            {form[index].multiple && (
              <MultipleSelect
                value={answers[key] || ['0']}
                question={{
                  label,
                  values: questionChoices[key] || [],
                  readOnly: isReadOnly(index),
                }}
                setValue={(answer) => {
                  answerQuestion(answer, key, true);
                }}
                index={index}
                setPopup={setPopup}
              />
            )}
          </form>
        ))}
        {popup}
        <div>
          <button
            onClick={onSubmit}
            type="button"
            style={{ display: 'block', margin: 'auto' }}
            disabled={!answers.Artikel}
          >
            Hinzufügen
          </button>
        </div>
      </section>
    </>
  );
}

/*
const optionsShape = {};
form.forEach(({ key }) => {
  optionsShape[key] = propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.oneOfType([
        propTypes.number,
        propTypes.string,
      ]),
      name: propTypes.string,
    }),
  );
});
Empfehlungen.propTypes = {
  options: propTypes.shape(optionsShape),
};
Empfehlungen.defaultProps = {
  options: {
    Kategorie: [
      { id: 0, name: 'Keine' },
      { id: 1, name: 'Test Category A' },
      { id: 2, name: 'Test Category B' },
    ],
    Hersteller: [
      { id: 0, name: 'Keine' },
      { id: 1, name: 'Test Hersteller A' },
      { id: 2, name: 'Test Hersteller B' },
    ],
    Bauform: [
      { id: 0, name: 'Keine' },
      { id: 1, name: 'Test Bauform A' },
      { id: 2, name: 'Test Bauform B' },
    ],
    Artikel: [
      { id: 0, name: 'Keine' },
      { id: 1, name: 'Test Artikel A' },
      { id: 2, name: 'Test Artikel B' },
    ],
    Zubehoer: [
      { id: 0, name: 'Keine' },
      { id: 1, name: 'Test Zubehoer A' },
      { id: 2, name: 'Test Zubehoer B' },
    ],
  },
};
*/

Empfehlungen.propTypes = {
  savedArtikel: propTypes.arrayOf(
    propTypes.instanceOf(Object),
  ),
  updateSavedArtikel: propTypes.func.isRequired,
};
Empfehlungen.defaultProps = { savedArtikel: [] };

export { form };
export default Empfehlungen;
