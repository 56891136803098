import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCommentAlt,
} from '@fortawesome/free-regular-svg-icons';

import Context from '../../ContextProvider';

function Footer() {
  const {
    address,
    telephone,
    email,
    logo,
    hours,
  } = useContext(Context);

  return (
    <footer>
      <div className="flex">
        <div className="contact-info">
          {logo}
          <address>
            <ul>
              <li className="address">
                <FontAwesomeIcon icon={faHome} />
                {address}
              </li>
              <li className="phone">
                <FontAwesomeIcon icon={faPhone} />
                <a href={`tel:${telephone}`}>{telephone}</a>
              </li>
              <li className="email">
                <FontAwesomeIcon icon={faCommentAlt} />
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            </ul>
          </address>
        </div>
        <div className="hours" dangerouslySetInnerHTML={{ __html: hours }} />
      </div>
      <div className="links">
        <ul>
          <li><Link to="datenschutz">Datenschutz</Link></li>
          <li><Link to="impressum">Impressum</Link></li>
          <li><Link to="agb">AGB</Link></li>
        </ul>
      </div>
      <div className="version">
        {process.env.REACT_APP_VERSION}
      </div>
    </footer>
  );
}

export default Footer;
