import React, { useEffect } from 'react';
import {
  Outlet,
  BrowserRouter,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import './App.css';

import { ContextProvider } from './ContextProvider';

import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

import Impressum from './components/Impressum';
import AGB from './components/AGB';
import Datenschutz from './components/Datenschutz';

function Content() {
  const loc = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loc, loc.pathname]);

  return (
    <ContextProvider>
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </ContextProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:key?" element={<Content />}>
          <Route path=":key?/impressum" element={<Impressum />} />
          <Route path=":key?/datenschutz" element={<Datenschutz />} />
          <Route path=":key?/agb" element={<AGB />} />
          <Route path="" element={<Body />} />
          <Route path="*" element={<Body />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
