import React from 'react';
import propTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { Buffer } from 'buffer';

function imageSrc(value) {
  try {
    const { data } = value;
    const encoding = 'binary';
    const outerBuffer = Buffer.from(data, encoding);
    const outerObject = JSON.parse(outerBuffer.toString('utf8'));
    const innerBuffer = Buffer.from(outerObject.data.data, encoding);
    const base64image = innerBuffer.toString('base64');
    const { mimetype } = outerObject;
    return [mimetype, base64image];
  } catch {
    return ['', ''];
  }
}

function BufferImage({ value, alt }) {
  const [mimetype, base64image] = imageSrc(value);
  if (mimetype.includes('svg')) {
    return <ReactSVG src={`data:${mimetype};base64,${base64image}`} />;
  }
  return <img src={`data:${mimetype};base64,${base64image}`} alt={alt} />;
}

BufferImage.propTypes = {
  value: propTypes.instanceOf(Object).isRequired,
  alt: propTypes.string,
};

BufferImage.defaultProps = {
  alt: '',
};

export { imageSrc };
export default BufferImage;
